/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./toastr.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@ngx-translate/core";
import * as i4 from "./toastr.component";
import * as i5 from "ngx-toastr";
var styles_ToastrComponent = [i0.styles];
var RenderType_ToastrComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ToastrComponent, data: { "animation": [{ type: 7, name: "flyInOut", definitions: [{ type: 0, name: "inactive", styles: { type: 6, styles: { opacity: 0 }, offset: null }, options: undefined }, { type: 1, expr: "inactive => active", animation: { type: 4, styles: { type: 5, steps: [{ type: 6, styles: { transform: "translate3d(100%, 0, 0) skewX(-30deg)", opacity: 0 }, offset: null }, { type: 6, styles: { transform: "skewX(20deg)", opacity: 1 }, offset: null }, { type: 6, styles: { transform: "skewX(-5deg)", opacity: 1 }, offset: null }, { type: 6, styles: { transform: "none", opacity: 1 }, offset: null }] }, timings: "400ms ease-out" }, options: null }, { type: 1, expr: "active => removed", animation: { type: 4, styles: { type: 5, steps: [{ type: 6, styles: { opacity: 1 }, offset: null }, { type: 6, styles: { transform: "translate3d(100%, 0, 0) skewX(30deg)", opacity: 0 }, offset: null }] }, timings: "400ms ease-out" }, options: null }], options: {} }] } });
export { RenderType_ToastrComponent as RenderType_ToastrComponent };
function View_ToastrComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [], [[1, "aria-label", 0]], null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), (_l()(), i1.ɵted(2, null, [" ", " "])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.options.titleClass; _ck(_v, 1, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 0, 0, currVal_0); var currVal_2 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_co.title)); _ck(_v, 2, 0, currVal_2); }); }
function View_ToastrComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["aria-live", "polite"], ["role", "alert"]], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.options.messageClass; _ck(_v, 1, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.message; _ck(_v, 0, 0, currVal_0); }); }
function View_ToastrComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["aria-live", "polite"], ["role", "alert"]], [[1, "aria-label", 0]], null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), (_l()(), i1.ɵted(2, null, [" ", " "])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.options.messageClass; _ck(_v, 1, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.message; _ck(_v, 0, 0, currVal_0); var currVal_2 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_co.message)); _ck(_v, 2, 0, currVal_2); }); }
function View_ToastrComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["class", "toast-progress"]], [[4, "width", null]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.width + "%"); _ck(_v, 1, 0, currVal_0); }); }
export function View_ToastrComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "row"]], [[4, "display", null]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "col-9"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ToastrComponent_1)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ToastrComponent_2)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ToastrComponent_3)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 0, "div", [["class", "col-3 text-right"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ToastrComponent_4)), i1.ɵdid(10, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.title; _ck(_v, 3, 0, currVal_1); var currVal_2 = (_co.message && _co.options.enableHtml); _ck(_v, 5, 0, currVal_2); var currVal_3 = (_co.message && !_co.options.enableHtml); _ck(_v, 7, 0, currVal_3); var currVal_4 = _co.options.progressBar; _ck(_v, 10, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.state.value === "inactive") ? "none" : ""); _ck(_v, 0, 0, currVal_0); }); }
export function View_ToastrComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-toastr", [], [[8, "className", 0], [40, "@flyInOut", 0]], [[null, "click"], [null, "mouseenter"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).tapToast() !== false);
        ad = (pd_0 && ad);
    } if (("mouseenter" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).stickAround() !== false);
        ad = (pd_1 && ad);
    } if (("mouseleave" === en)) {
        var pd_2 = (i1.ɵnov(_v, 1).delayedHideToast() !== false);
        ad = (pd_2 && ad);
    } return ad; }, View_ToastrComponent_0, RenderType_ToastrComponent)), i1.ɵdid(1, 180224, null, 0, i4.ToastrComponent, [i5.ToastrService, i5.ToastPackage], null, null)], null, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).toastClasses; var currVal_1 = i1.ɵnov(_v, 1).state; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
var ToastrComponentNgFactory = i1.ɵccf("app-toastr, [app-toastr]", i4.ToastrComponent, View_ToastrComponent_Host_0, {}, {}, []);
export { ToastrComponentNgFactory as ToastrComponentNgFactory };
